<template>
  <div class="stack">
    <transition name="bubble">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ContainerAccess',
  methods: {
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.tariff.id);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
}
</style>
